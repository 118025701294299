/*TOOLTIP*/
.tooltip {
    position: relative;
    margin: 0;
    padding: 3px;
    list-style: none;
    border: 1px solid #e9e9e9;
    background-color: rgba(255, 255, 255, 0.9);
}

.tooltip-item {
    margin: 5px 0 0 0;
    z-index: 10;
}

.tooltip-item .name, 
.tooltip-item .value {
    display: block;
    margin: 0 auto;
    text-align: center;
}

.tooltip-item .name {
    font-size: 12px;
    z-index: 100;
    font-weight: 700;
}

.tooltip-item .value {
    font-size: 16px;
    font-weight: 300;
}

.no-data {
    font-style: italic;
    font-size: 0.75em;
}

.invalid {
    font-style: italic;
    font-size: 0.75em;
    color: #ccc;
}

.meme {
    width: 150px;
    margin-top: 15px;
}