footer .footer-top {
  background-color: #fcfcfc;
  border-top: 1px solid #e9e9e9;
  color: #777;
  padding: 70px 0px;
  box-sizing: border-box;
}

.footer-top-left-content {
  float: left;
  width: calc(100% / 12 * 8);
}

.footer-top-left-content img {
  width: 180px;
  height: auto
}
.footer-top-left-content p {
  display: inline-block;
}

.footer-top-left-content p {
  text-align: left;
  margin: 5px 0 25px 0;
  font-size: 13px;
  line-height: 24px;
}

.footer-top-left-content img {
  float: left;
  padding-bottom: 25px;
}

.footer-top-right-content {
  float: left;
  width: calc((100% / 12 * 4) - 82.156px);
  margin-left: 82.156px;
  padding: 0 15px;
  box-sizing: border-box;
  text-align: left;
}

.footer-top-right-content-information {
  margin: 0px 0px 30px 0px;
  padding: 0px;
  color: #363636;
  font-size: 20px;
  font-weight: 300;
  line-height: 38px;
}

.footer-top-right-content-contact {
  display: block;
  padding: 8px 0px;
  color: #777;
  font-size: 13px;
  text-decoration: none;
}

.footer-top-right-content-contact:hover {
  text-decoration: underline;
}

footer .footer-bottom {
  background-color: #f7f7f7;
  color: #777;
  font-size: 12px;
  padding: 40px 0px 38px;
  box-sizing: border-box;
}

.footer-bottom-right-content-text {
  text-align: left;
  margin: 0 0 1px 0;
  overflow: -webkit-paged-y;
}

.footer-bottom-right-content-text a {
  color: #777;
  text-decoration: none;
  border-bottom: 1px solid #777;
}

@media (max-width: 768px) {
  .footer-top {
    min-height: 446px;
  }
  .footer-bottom {
    min-height: 112px;
  }
  .footer-top-left-content,
  .footer-top-right-content {
    width: 100%;
    margin-left: 0;
    padding: 0;
  }
  .footer-top-left-content img {
    padding-top: 30px;
  }
  .footer-top-right-content-information {
    margin-top: 30px;
  }
}