.main-title-wrapper {
    display: flex;
    width: 100%;
    max-width: 1733px;
    height: 200px;
    margin: 0 auto;
    background-image: url('https://sueldos.openqube.io/encuesta-sueldos-2023.01/back.svg'); 
    background-position: 0;
    background-size: cover
    /* filter: hue-rotate(-30deg); */
}

.main-title {
    display: inline-block;
    align-self: center;
    padding: 0 20px;
    margin: 11px auto;
    color: #FFFFFF;
    font-family: 'Red Hat Display', sans-serif;
    font-size: 38px;
    font-weight: 300;
    box-sizing: border-box;
    text-transform: uppercase;
}

.main-title span {
    margin: 0;
    line-height: 40px;
}

.main-title .first-line {
    font-size: 22px;
}

@media (min-width: 340px) and (max-width: 768px) {
    .main-title {
        margin-top: 11px;
    }
}

@media (max-width: 768px) {
    .main-title-wrapper {
        height: 160px;
    }
    .main-title {
        padding: 0 20px;
        font-size: 22px;
    }
}