.sticky-wrapper {
    width: calc((100% / 12 * 3) - 10px);
    min-height: 100vh;
}
.side-nav-wrapper {
    width: 100%;
    top: 0;
    margin-right: 10px; 
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    box-sizing: border-box;
    max-height: 95vh;
    overflow: scroll;
    box-sizing: border-box;
}

.side-nav {
    width: 100%;
}

.side-nav-wrapper ul {
    padding: 10px 10px 20px 20px;
    list-style: none;
    box-sizing: border-box;
    counter-reset: item;
}

.side-nav-wrapper > ul {
    padding-bottom: 0;
}

.side-nav-wrapper li {
    text-align: left;
}

.side-nav-wrapper li:before {
    content: counters(item, ".") ".";
    counter-increment: item;
    font-size: 0.75em;
    margin: 0 5px;
    opacity: 0.6;
    color: #763DFE;
}

.side-nav-wrapper li.is-current:before {
    opacity: 1;
} 
.side-nav-wrapper li a {
    color: #313142;
    text-decoration: none;
}

li.is-current > a {
   color: #1E285F;
   font-weight: 700;
}

.item-title {
    font-size: 12px;
}

@media (max-width: 768px) {
    .sticky-wrapper {
        display: none;
    }
}