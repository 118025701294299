@font-face {
  font-family: 'Red Hat Display' ;
  src: url('./fonts/RedHatDisplay-Regular.ttf')  format('ttf');
}


body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", */
  font-family: 'Red Hat Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Red Hat Display',
    sans-serif;
}

/*colores*/
/*

COLORES LOGO
#6D72AA violeta oscuro
#9167B1 violeta claro
#ED5C88 rosa
#F4A04B naranja

COLOR VIOLETA PAGE
#623296

*/