.tabs-item {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #e9e9e9;
    border-bottom-color: transparent;
    margin: 0 2px -1px 0;
    font-size: 14px;
    cursor: pointer;
    color: #363636;
    box-sizing: border-box;
}

.tabs-item.active {
    border-top: 2px solid #ED5C88;
    border-bottom-color: #FFF; 
    color: #623296;
}

@media (max-width: 460px) {
    .tabs-item{
        display: block;
        width: 100%;
        text-align: center;
        font-size: 12px;
    }
  }
