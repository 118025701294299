 .main {
  width: 100%;
  padding: 60px 0;
  box-sizing: border-box;
  background-color: #fff; 
}

@media (max-width: 768px) {
  .main {
    padding: 0 0 20px;
  }
}


