button.cta {
    padding: 10px 20px;
    background-color: #763DFE; 
    border-radius: 4px;
    transition: all ease-in-out 0.3s;
}

button.cta:hover {
    background-color: #313142;
    transition: all ease-in-out 0.3s;
}

.cta-link {
    padding: 10px 20px;
    text-decoration: none;
    color: #fff;
    font-size: 18px;
}