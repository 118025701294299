.container {
    display: inline-flex;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}


@media (min-width: 1200px) {
  .container {
      width: 992px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 750px;
  }
}

@media (max-width: 768px) {
  .container.footer {
    display: block;
  }
}

