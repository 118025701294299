.tabs-list {
    list-style: none;
    text-align: left;
    padding: 0;
    border-bottom: 1px solid #e9e9e9;
    box-sizing: border-box;
}

.tab-content {
    text-align: left;
}

.tab-content-caption {
    font-size: 0.8em;
    text-align: center;
}

.tab-content-description {
    margin-top: 10px;
    font-size: 0.8em;
    color: #444;
    text-align: center;
}