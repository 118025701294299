@font-face {
  font-family: 'Red Hat Display' ;
  src: url('./fonts/RedHatDisplay-Regular.ttf')  format('ttf');
}

body {
  font-family: 'Red Hat Display', sans-serif;
  line-height: 1.42857;
  -webkit-font-smoothing: unset;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
